.details-media--vertical {
  text-align: center;
  .details-360,
  .details-video {
    display: inline-block;
  }
  &__header {
    font-weight: 700;
    margin: 0;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.9rem;
    letter-spacing: 0;
    text-transform: uppercase;
    margin: 0 0.5rem;
    cursor: pointer;
    -webkit-transition: color 0.3s ease-out;
    -moz-transition: color 0.3s ease-out;
    -o-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
    color: #000;
    &:hover {
      color: $primary-color;
    }
  }
  &__img {
    width: 25px;
  }
}
