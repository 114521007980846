.hp .fitment {
  background-color:$blue;
  padding-top:1rem;
  padding-bottom:2rem;
  .title {
    text-align:center;
  }
  h3 {
    line-height:1.4;
    a {
      color:$white;
      transition: all .3s ease-in-out;
      &:hover {
        color:$gray6;
      }
    }
  }
  .tabs,.tabs-content {
    background:transparent;
    border:0;
  }
  .tabs {
    display:flex;
    justify-content:center;
    @include breakpoint (large) {
      margin-bottom:1rem;
    }
    .tabs-title {
      /*width:100%;
      @include breakpoint (large) {
        width:50%;
      }
      &:first-child {
        border-right:1px solid $white;
      }
      &:last-child {
        border-left:1px solid $white;
      }*/
      > a {
        color:$white;
        font-size:1.3rem;
        text-transform: uppercase;
        font-weight:600;
        background:transparent;
        padding:0;
        border-bottom:2px solid transparent;
        transition: all .3s ease-in-out;
        @include breakpoint (large) {
          font-size:2.25rem;
        }
        &:hover {
          background-color:transparent;
        }
      }
      &.is-active {
        a {
          border-radius:2px;
          //background:$black;
          border-bottom-color: $white;
        }
      }
    }
  }
  .links {
    display:flex;
    justify-content:center;
    align-items:center;
    list-style: none;
    line-height:1;
    margin-bottom:1rem;
    @include breakpoint (large) {
      margin-bottom:1.5rem;
    }
    li {
      /*width:100%;
      @include breakpoint (large) {
        width:50%;
      }
      &:first-child {
        border-right:1px solid $white;
      }
      &:last-child {
        border-left:1px solid $white;
      }*/
      > a,span {
        color:$white;
        font-size:1.3rem;
        text-transform: uppercase;
        font-weight:600;
        background:transparent;
        padding:0;
        border-bottom:2px solid transparent;
        transition: all .3s ease-in-out;
        border-bottom-color: $white;
        @include breakpoint (large) {
          font-size:2.25rem;
        }
        &:hover {
          background-color:transparent;
          border-bottom-color:$blue2;
        }
      }
      span {
        border-bottom-color:transparent;
        &:hover {
          color:$white;
          border-bottom-color:transparent;
        }
      }


      &.is-active {
        a {
          border-radius:2px;
          //background:$black;
          border-bottom-color: $white;
        }
      }
    }
  }
  .divider {
    font-size:1.3rem;
    color:$white;
    font-weight:600;
    padding-left:.5rem;
    padding-right:.5rem;
    line-height:1;
    @include breakpoint (large) {
      font-size:2.25rem;
    }
  }
  .tabs-panel {
    @include breakpoint (large) {
      padding:0;
    }
  }
  #icv_vehicle-select {
    @include breakpoint (large) {
      display:flex;
      flex-wrap: nowrap;
    }
    > select {
      border-radius: 3px;
      @include breakpoint (large) {
        margin:.5rem;
      }
    }
  }
}