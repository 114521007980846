.tire-cta {
  .button-group {
    margin: 0;
    li {
      margin-top: 1rem;
    }
    .button {
      width: 100%;
    }
  }
}