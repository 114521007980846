@mixin tear ($pseudoElem, $color, $top, $bot, $z-index) {
  @if $pseudoElem == 'top' {
    &::before {
      position: absolute;
      content: ' ';
      top: $top;
      right: 0;
      left: 0;
      height: 1rem;
      z-index: $z-index;
      background: url('/images/home/tear/#{$color}-top.png') top center repeat-x;
    }
  }
  @if $pseudoElem == 'bot' {
    &::after {
      position: absolute;
      content: ' ';
      bottom: $bot;
      right: 0;
      left: 0;
      height: 1rem;
      z-index: $z-index;
      background: url('/images/home/tear/#{$color}-bot.png') bottom center repeat-x;
    }
  }
}
