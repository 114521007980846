.hero-slider {
  position: relative;
  @include tear('bot', 'blue', 0, 0, 1);
  .slick-slide {
    position: relative;
  }
  .hp-slider {
    position: relative;
    img {
      width: 100%;
    }
    .slider-caption {
      position: absolute;
      width: 100%;
      bottom: 47%;
      z-index: 10;
      color: #ffffff;
    }
  }
  .slick-slider {
    margin-bottom: 0px;
  }
}
.hero-row {
  max-width: 65rem;
  width:100%;
  margin-left: auto;
  margin-right: auto;
}
.hero-header {
  font-size: 3rem;
  @include breakpoint(large) {
    font-size: 5rem;
    line-height: 0.9;
  }
}
.hero-tagline {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1;
  @include breakpoint(large) {
    font-size: 2rem;
  }
}
.hero-btn {
  border-radius: 50px;
  margin-top: 1rem;
  padding: 0.8rem 1.2rem;
}
.hero-content {
  background-size: cover;
  top: 0;
  z-index: 2;
  width: 100%;
  height:100%;
  display:flex;
  justify-content: flex-start;
  align-items: center;
  @include breakpoint (medium down) {
    background-color: $black;
    padding: 1rem;
  }
  @include breakpoint (large up) {
    position: absolute;
    background-image: none;
  }
}

.hp-slider {
  transition: all .3s ease-in-out;
  img {
    width:100%;
  }
  .slick-list {
    &:before {
      position: absolute;
      display: block;
      content: '';
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 9999;
      height: 1rem;
      background: url(/images/home/tear/blue-bot.png) top center repeat-x;
    }
  }
  .slick-slide {
    position:relative;
    width:100%;
    img {
      width:100%;
    }
  }
  .slider-caption {
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    display:flex;
    align-items:center;
    transition: all .3s ease-in-out;
    @include breakpoint (medium down) {
      display:none;
    }
    @include breakpoint (large up) {

    }
    &-content {
      margin:0 auto;
      max-width:65em;
      width:100%;
    }
  }
}