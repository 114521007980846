#wheel {
  .wheel-detail-stage {
    position: relative;
    //margin-bottom:3rem;
    @include breakpoint (large) {
      margin-bottom:7rem;
    }
  }
  



  .stage-container {
    
      display:flex;
      justify-content: center;
      flex-direction:row-reverse;
    
    > div {
      &:last-child {
        width:20%;
        @include breakpoint (large) {
          width:15%
        }
      }
      &:first-child {
        width:80%;
        @include breakpoint (large) {
          width:85%;
        }
      }
    }
    .alt-images {
      .slick-slide {
        @include breakpoint (medium) {
          width:100%!important;
        }
      }
      .slick-track {
        @include breakpoint (medium) {
          width:100%!important;
        }
      }
    }
  }
}



.main-image {
  margin-bottom: 0;
  img {
    margin: 0 auto;
  }
}

.alt-images {
  
  margin:0 auto 2rem;
  @include breakpoint (large) {
    margin-bottom: 0 !important;
    max-width:60%;
  }
  .slick-track {
    margin: 0 auto;
  }
  &--retailer {
    @include breakpoint(medium up) {
      .slick-track {
        /*width: 100% !important;*/
        /*margin-top: 3rem;*/
      }
    }
  }
  @include breakpoint(small) {
    .slick-dots {
      position: relative !important;
      text-align: center;
      bottom: 0;
      margin: 0;
    }
  }
  .slick-slide {
    &:focus {
      outline: 0;
    }
    img {
      max-width:100px;
      margin:0 auto;
      width:100%;
    }
  }
  a {
    margin-right: 0;
    padding: .7rem;
  }
  img {
    transition: all .3s ease-in-out;
    opacity:.5;
    &:hover {
      backface-visibility: hidden;
      transform: scale(1.08);
    }
  }
  .slick-active {
    &:after {
      width:0;
      transition: all .3s ease-in-out;
      content:'';
      display:block;
      margin:0 auto;
      height:5px;
      background-color:$primary-color;
    }
  }
  .slick-current {
    &:after {
      width:80%;
    }
    img {
      opacity: 1;
    }
  }
}

// media buttons
#wheel {
  .details-media {
    position: absolute;
    top:0;
    right:2rem;
  }
}




// zoom over image function
.zoom {
  display:inline-block;
  position: relative;
  &:after {
    content:'';
    display:block; 
    width:33px; 
    height:33px; 
    position:absolute; 
    top:0;
    right:0;
    background:url(icon.png);
  }
  img {
    display:block;
      &::selection {
        background-color:transparent;
      }
  }
}



/*
.easyzoom {
  position: relative;
   'Shrink-wrap' the element 
  display: inline-block;
  max-width: 100%;
  *display: inline;
  *zoom: 1;
}

.easyzoom img {
  vertical-align: bottom;
}

.easyzoom.is-loading img {
  cursor: progress;
}

.easyzoom.is-ready img {
  cursor: crosshair;
}

.easyzoom.is-error img {
  cursor: not-allowed;
}

.easyzoom-notice {
  line-height: 2em;
  position: absolute;
  z-index: 150;
  top: 50%;
  left: 50%;
  width: 10em;
  margin: -1em 0 0 -5em;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 10px #888;
}

.easyzoom-flyout {
  position: absolute;
  z-index: 100;
  overflow: hidden;
  background: #fff;
}

.easyzoom-flyout img {
  max-width: none;
}



.easyzoom--overlay .easyzoom-flyout {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.easyzoom--adjacent .easyzoom-flyout {
  top: -10px;
  left: 75%;
  width: 350px;
  height: 350px;
  margin-left: 20px;
  border: 3px solid #333;
}










*/