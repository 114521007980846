.media-container {
  padding-top: 1rem;
}

.main-image {
  margin-bottom: 0;
  img {
    margin: 0 auto;
  }
}

.alt-images-container {
  padding-bottom: 1rem;
}

.alt-images--vertical {
  @include breakpoint(small) {
    .slick-dots {
      position: relative !important;
      text-align: center;
      bottom: 0;
      margin: 0;
    }
  }
  .slick-slide:focus {
    outline: 0;
  }
  a {
    margin-right: 0;
    padding: 0.7rem;
  }
  img {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
    &:hover {
      backface-visibility: hidden;
      transform: scale(1.08);
    }
  }
}
