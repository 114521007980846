.tire-details {
    background: #f1f1f1;
    padding: 1rem 1.5rem;
}

#tire {
  margin-top:3rem;
  margin-bottom:3rem;
  @include breakpoint (large) {
    margin-top:4rem;
    margin-bottom:5rem;
  }
  .row {
    max-width:90rem;
  }

  .about {
    margin-bottom:1rem;
  }
  // financing / promotion
  .financing {
    padding-left:.65rem;
    padding-right:.65rem;
  }
}