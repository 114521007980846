
.promo {
  background-color:$black;
  color:$blue2;
  font-size:1.5rem;
  text-transform: uppercase;
  text-align:center;
  font-weight:700;
  padding-top:1.75rem;
  padding-bottom:1.75rem;
  line-height:1;
  margin-bottom:1rem;
  @include breakpoint (large) {
    font-size: 2.5rem;
    margin-bottom:3rem;
  }
  a {
    color:$white;
    @include breakpoint (medium down) {
      display:inline-block;
      width:100%;
    }
  }
  &-header {
    
    img {
      max-width:36px;
      margin-right:.25rem;
      @include breakpoint (medium) {
        max-width:38px;
        margin-right:0;
      }
    }
    a {
      color:$blue2;
      font-size:.9rem;
      white-space: inherit;
      .subtitle {
        color:$white;
        @include breakpoint (medium down) {
          display:none;
        }
      }
      .title {
        @include breakpoint (medium) {
          font-size:1.35rem;
        }
      }
    }
    &.nav-phone-sm {
      display:flex;
      align-items:center;
      width:100%;
      line-height:1.25;
      padding-left:1rem;
      @include breakpoint (large) {
        display:none;
      }
    }
  }
}
.header--sm {
  @include breakpoint (medium down) {
    align-items:center;
  }
  .logo {
    img {
      @include breakpoint (small only) {
        max-width:150px;
      }
    }
  }
}