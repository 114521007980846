.hp .vehicle-select {
  &-header {
    font-size: 1.3rem;
    @include breakpoint(medium up) {
      font-size: 2.5rem;
    }
  }
  &-subheader {
    padding: 0 0.5rem;
    margin-bottom: 0;
    font-size: 0.9rem;
    color:$white;
    @include breakpoint(medium up) {
      font-size: 1.5rem;
    }
  }
  select {
    border-radius: 3px;
    font-weight: 500;
    border: none;
    &:disabled {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  &__btn {
    background-color: $black;
    border-radius: 3px;
    width: 100%;
    margin: 0;
  }
}

.vehicle-select-columns #app {
  display: flex;
  justify-content: space-between;

  label {
    flex-grow: 1;
    margin: 0.5rem;
  }
}

.vehicle-select-columns select {
  border-radius: 3px;
  font-weight: 500;
  border: none;
}

.vehicle-select-columns select:disabled {
  background-color: rgba(0, 0, 0, 0.3);
  color:$grayC;
}

.vehicle-select-horizontal {
  display: block;
}

.fitment-clear-btn button {
  margin: 0.5rem;
}

@media (max-width: 1024px) {
  .vehicle-select-columns #app {
    display: block;

    label {
      margin: 0.5rem;
    }
  }
}
