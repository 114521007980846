.page {
    padding-bottom: 50px;
    &__content {
        padding-top: 50px;
        h1, h2 {
            text-transform: initial;
            font-family:"Roboto Condensed";
        }
    }
}
.page-header {
    font-size: 2.5rem;
    font-weight: 700;
    font-family:"Roboto Condensed";
}

