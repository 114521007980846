.t {
  &-uppercase {
    text-transform: uppercase;
  }
  &-center {
    text-align: center;
    &--sm {
      @include breakpoint(small only) {
        text-align: center;
      }
    }
  }
  &-right {
    text-align: right;
  }
}

.color {
  &--gray {
    color: $medium-gray;
  }
}

.margin {
  &--tb {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.padding {
  &--tb {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.f {
  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}