.fitmentCheck {
  &__txt {
    color: $primary-color;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  &__btn {
    background-color: $primary-color;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}