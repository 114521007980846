.wheel-cta {
  span {
    background-color:$green;
    color:$white;
    border-radius:50px;
    width:55px;
    height:55px;
    display:inline-flex;
    justify-content: center;
    align-items: center;
    font-size:1.5rem;
    margin-right:.5rem;
  }
  a {
    text-transform: uppercase;
    font-weight:700;
    color:$green;
    display:flex;
    align-items: center;
  }
  ul {
    margin:0;
    list-style: none;
    @include breakpoint (medium) {
      display:flex;
    }
    @include breakpoint (large) {
      justify-content: space-between;
    }
    li {
      margin-bottom:1rem;
      @include breakpoint (medium) {
        margin-bottom:0;
      }
    }
  }
  img {
    max-width:33px;
  }
}