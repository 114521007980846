.wheel-specs--grid {
  @include breakpoint(small only) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  &__header {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
  &__details {
    margin-left: 0;
    list-style: none;
    font-weight: 600;
  }
}

.wheel-specs-grid {
  display: grid;
  grid-template-columns: 20% repeat(3, auto) 20%;
  grid-auto-rows: 1fr;
  margin-bottom: 1rem;
  background-color: #ccc;
  @include breakpoint(small only) {
    grid-template-columns: repeat(3, auto);
    grid-auto-rows: 1fr;
  }
  .cell {
    padding: 0.2rem;
    border: 1px solid #ccc;
    border-collapse: collapse;
    line-height: 1.2;
  }
  .spec {
    &-label {
      display: block;
      font-size: 0.9rem;
      margin-bottom: 0.2rem;
      &--price {
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
    &-value {
      font-size: 1.1rem;
      font-weight: 600;
    }
    &--md {
      font-size: 1.3rem;
    }
    &--lg {
      font-size: 1.7rem;
      line-height: 0.9;
    }
    &--shifted {
      margin-left: 0.5rem;
    }
  }
  .no-value {
    font-size: 1.2rem;
  }
  .black {
    background-color: $black;
    color: $white;
  }
  .gray {
    background-color: $gray;
    color: $white;
    font-weight: 500;
  }
  .flex {
    display: flex;
    flex-direction: column;
    &-center {
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    &-split {
      justify-content: space-between;
      &--center {
        align-self: center;
      }
      &--right {
        align-self: flex-end;
      }
    }
  }
  .name {
    @include breakpoint(small only) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }
  }
  .partNumber {
    grid-column: 2 / 5;
    display: flex;
    align-items: center;
    @include breakpoint(small only) {
      grid-column: 2 / 4;
      grid-row: 1 / 2;
    }
  }
  .size {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    @include breakpoint(small only) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
  }
  .boltPat {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    @include breakpoint(small only) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }
  .offset {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    @include breakpoint(small only) {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }
  }
  .hub {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
    @include breakpoint(small only) {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
    }
  }
  .price {
    grid-column: 5 / 6;
    grid-row: 1 / 3;
    padding: 0.3rem;
    @include breakpoint(small only) {
      grid-column: 3 / 4;
      grid-row: 2 / 4;
    }
  }
}

.bp-toggle-btn {
  color: $primary-color;
}
