.selectedVehicleBar {
	position: relative;
	background-color: #ebebeb;
	img {
		max-height: 100px;
	}
	.row--small {
		max-width: 67rem;
	}
	&__content {
		margin: 0 auto;
		padding-left: 0;
		padding-right: 0;
		@include breakpoint(medium up) {
			margin: 1.5rem auto 0;
		}
		&--noMargin {
			margin: 0;
		}
	}
	&__text {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: 0;
		@include breakpoint(medium up) {
			font-size: 2rem;
		}
	}
	&__span {
		color: #6a6a6a;
	}
	&__btn {
		width: 100%;
		margin-bottom: 0;
		&--smaller {
			padding-top: 1rem;
			padding-bottom: 1rem;
			font-size: 1rem;
		}
	}
}