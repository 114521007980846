.wheel-cta-grid {
  max-width: 100%;
  margin: 1rem;
  display: grid;
  grid-template-columns: 48% 48%;
  grid-gap: 0.5rem;
  @include breakpoint(small only) {
    .bottom-right {
      grid-column: 1 / 3;
    }
  }
  @include breakpoint(large up) {
    grid-template-columns: 32% 32% 32%;
    margin: 2rem 0 0 auto;
  }
  &__btn {
    position: relative;
    margin: 0;
    border-radius: 25px;
    width: 100%;
    text-align: left;
    text-transform: initial;
    font-size: 1rem;
    line-height: 1;
    &:after {
      content: '';
      position: absolute;
      top: 6%;
      right: 4%;
      height: 50px;
      width: 50px;
    }
    &--tire:after {
      background: url(/images/wheels/cta-icons/tire.png);
      background-size: contain;
      background-repeat: no-repeat;
      @include breakpoint(medium only) {
        width: 40px;
        height: 40px;
      }
    }
    &--cart:after {
      height: 44px;
      width: 50px;
      background: url(/images/wheels/cta-icons/cart.png);
      background-size: contain;
      background-repeat: no-repeat;
      @include breakpoint(medium only) {
        width: 40px;
        height: 40px;
      }
    }
    &--quote:after {
      background: url(/images/wheels/cta-icons/quote.png);
      background-size: contain;
      background-repeat: no-repeat;
      @include breakpoint(medium only) {
        width: 40px;
        height: 40px;
      }
    }
  }
}
