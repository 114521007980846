.tire-cta-hp {
  display: grid;
  position: relative;
  @include tear('top', 'white', 0, 0, 1);
  @include tear('bot', 'gray', 0, 0, 1);
  &-bg {
    &--lg {
      width: 100%;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 1;
      @include breakpoint(medium down) {
        display: none;
      }
    }
    &--sm {
      background: url('/images/home/tire-cta-bg.jpg') no-repeat;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 1;
      grid-column-end: 1;
    }
  }
  &-content {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 1;
    padding: 1rem;
    z-index: 2;
    @include breakpoint(large up) {
      padding: 10% 0;
    }
  }
  &-header {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    @include breakpoint(medium up) {
      font-size: 4rem;
      line-height: 1;
      margin-bottom: 0;
    }
    &--lg {
      font-size: 2.5rem;
      @include breakpoint(medium up) {
        font-size: 5rem;
        line-height: 1;
      }
    }
  }
  &-txt {
    color: $white;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1.2;
    margin-bottom: 2rem;
  }
  &-btn {
    background-color: $white;
    color: $black;
  }
}