.packages {
  background-color: #F5F5F5;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  position: relative;
  width: auto;

  .item-name {
    font-size: 1.5rem;
  }

  .returnTxt {
    font-size: 1.6rem;
    margin-top: 1rem;
  }
}