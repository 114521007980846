.home-vehicle-packages {
  &__header {
    font-size: 2.9rem;
  }
  .column-block {
    margin-top: 1.2rem;
  }
  &__img {
    border: none;
    box-shadow: none;
    &:hover {
      transform: scale(1.2) rotate(-3deg);
      transition: all 300ms;
      box-shadow: none;
    }
  }
  .vehicle-title {
    font-size: 1rem;
    color: $medium-gray;
    text-transform: uppercase;
  }
}
