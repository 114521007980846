.footer-top {
  background: url('/images/footer-bkg.jpg');
  position: relative;
  @include tear('top',
  'black',
  0,
  0,
  1);
}

.company-info {
  color: $white;
  font-weight: 400;
  font-size: 0.8rem;
  a {
    color: $primary-color;
  }
}

.footer-legal {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 1.2rem 0;
  @include breakpoint(large up) {
    font-size: 1rem;
  }
}

.social-links {
  z-index: 1;
  &__link {
    margin: 0 0.5rem;
    &:hover i {
      color: $primary-color;
      transition: 0.2s ease-in-out;
    }
  }
}