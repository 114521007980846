.news-article {
    position: relative;
    overflow: auto;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    .timestamp {
        font-size: .8rem;
        position: absolute;
        top: 0;
        left: 0;
        padding: .5rem;
        text-align: center;
        color: #fefefe;
        background: #333;
        .date {
            font-size: 2.75rem;
            font-weight: 900;
            line-height: 1;
            display: block;
        }
    }
    img {
        border-bottom: 5px solid #d40000;
    }
    .news-sidebar {
        padding: 1rem;
        background: #fbfbfb;
        li a {
            color: #505050;
            &:hover {
                color: #d40000;
            }
        }
    }
}