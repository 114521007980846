.view-on-vehicle-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  @include breakpoint(large up) {
    padding-bottom: 0;
  }
}

/* View On Vehicle Styling Overrides */
.spin-icon {
  padding-top: 4rem;
}
