.wheel-gallery--long {
  .toggle-btn {
    float: right;
    margin: 0 0.5rem;
    @include breakpoint (large up) {
      margin: 0 1.5rem;
    }
  }
  .wheel-galleryList__item:nth-child(n + 7) {
    display: none;
  }
}
.wheel-gallery--display:nth-child(n + 7) {
  display: block !important;
}
.wheel-galleryList--long {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  &__item {
    list-style: none;
    width: 50%;
    height: 100px;
    margin-right: 0;
    margin-top: 0;
    text-align: center;
    overflow: hidden;
    &:nth-child(n + 7) {
      display: none;
    }
    &--display:nth-child(n + 7) {
      display: block;
    }
    @include breakpoint(medium up) {
      width: 24%;
    }
    @include breakpoint(large up) {
      width: 16%;
    }
  }
}

.wheel-galleryImage {
  width: 190px;
  height: 112px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
