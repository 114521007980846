.featured-packages-header {
  font-size: 2rem;
  text-align: center;
  @media screen and (min-width: 640px) {
      font-size: 4.5rem;
      text-align: center;
  }
}


.featured-packages {
  padding: 3rem 0;
  position: relative;
}

.featured-packages::before {
  position: absolute;
  content: ' ';
  top: 0;
  right: 0;
  left: 0;
  height: 1rem;
  z-index: 1;
  background: url('/images/home/tear/blue-top.png') top center repeat-x;
}

.info-banner::before {
  position: absolute;
  content: ' ';
  top: 0;
  right: 0;
  left: 0;
  height: 1rem;
  z-index: 1;
  background: none;
}

.info-banner {
  background-color: #eaeaea;
}

.pkg-info-description {
  font-family: "Montserrat", "Roboto Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #0a0a0a;
}
