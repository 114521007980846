.featured-wheels {
  display: grid;
  grid-template-areas: 'txt' 'bkg' 'wheels' 'buttons'; // compiler is messing this up;
  //grid-template-areas: 'a' 'b' 'c' 'd';
  grid-template-rows: 14rem 7rem auto 5rem;
  grid-gap: 1.5rem;
  @include breakpoint(large) {
    grid-template-rows: 1fr 11rem 12rem 5rem;
  }
  &-bkg {
    grid-area: bkg;
    grid-row-start: a-start;
    grid-row-end: b-end;
    width: 100%;
    z-index: -1;
    .tear {
      @include tear('top', 'gray', 0, 0, 1);
      @include tear('bot', 'white', 0, 0, 1);
      position: relative;
    }
  }
  &-txt {
    grid-area: txt;
  }
  &-header {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    @include breakpoint(medium up) {
      font-size: 3.5rem;
    }
    &--lg {
      font-size: 2.5rem;
      @include breakpoint(medium up) {
        font-size: 4.5rem;
      }
    }
  }
  &-brand {
    max-height: 4.5rem;
  }
  &-items {
    grid-area: wheels;
    grid-row-start: b-start;
    grid-row-end: c-end;
    &__grid {
      display: grid;
      grid-template-columns: 1fr;
      @include breakpoint(medium up) {
        grid-template-columns: repeat(3, 33.3%);
      }
    }
    &__header {
      max-width: 80%;
      margin: 0 auto;
      color: $black;
      font-size: 1.15rem;
      font-weight: 500;
      text-transform: initial;
      @media (min-width: 1400px) {
        background-color: rgba(0, 0, 0, 0.8);
        padding: 0.5rem;
        color: $white;
      }
    }
  }
  &-btns {
    grid-area: buttons;
  }
}