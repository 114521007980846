.newsletter {
  background-color: #eaeaea;
  padding: 1rem 0.5rem;
  position: relative;
  @include breakpoint(medium up) {
    padding: 2.5rem;
  }
  &--position {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include breakpoint(small only) {
      flex-direction: column;
    }
  }
  &__header {
    color: $primary-color;
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 0.7rem;
    @include breakpoint(medium up) {
      font-size: 2rem;
      margin: 0;
    }
  }
  &__form {
    background-color: $white;
    padding: 0.2rem 1rem;
  }
}

.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  &__input {
    display: inline-block;
    border: none;
    box-shadow: none;
    margin: 0;
    &:focus {
      border: none;
      box-shadow: none;
    }
    &--font {
      font-family: FontAwesome, 'roboto';
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 500;
    }
  }
  &__btn {
    font-family: 'fontawesome';
    background-color: $white;
    color: $black;
    margin: 0;
    padding: 0.3rem;
    font-size: 1.3rem;
    &:hover {
      background-color: transparent;
      color: $primary-color;
    }
  }
}
