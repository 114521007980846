#error-page {  
  // content
  .page-content {
      background-image: url("/images/utility/error-bg.png");
      background-position: center 5%;
      background-repeat: no-repeat;
      background-size: 60%;
      padding: 75px;
      margin-top: 75px;
      position: relative;
  
  
    
      h3 {
        font-size: 2.5rem;
        font-weight: 1000;
        color: $primary-color;
        margin-top: 3rem;
      }
    
      p {
        font-weight: 400;
        color: black;
        font-size: 1rem;
        margin-bottom:2rem;
        a {
          color:$primary-color;
          font-weight: 700;
          &:hover {
            color:$black;
          }
        }
      }
      ul {
        list-style: none;
        margin-left:0;
        margin-bottom:2rem;
      }
      .button {
        max-width:260px;
        width:100%;
      }
    }
  }