#wheel-brands {
  .page-content.padded.extra {
    @include breakpoint (small only) {  
      padding-top:0;
    }
    > .row {
      max-width:95rem;
    }
  }
  // top filter
  .top {
    margin-bottom:1.25rem;
  }
  .brand-letter-search {
    #wheel-filter-search {
      //padding-left:.25rem;
      ul {
        margin:0;
        list-style: none;
        @include breakpoint (xlarge) {
          display:flex;
          justify-content: space-between;
        }
        > li {
          display:inline-block;
          margin-bottom:.35rem;
          @include breakpoint (xlarge) {
            //display
          }
          &:last-child {
            margin-right:0;
            a {
              width:96px;
              @include breakpoint (xlarge) {
                width:40px;
              }
            }
          }
        }
      }
    }
    a {
      width:46px;
      height:52px;
      display:flex;
      align-items:center;
      justify-content:center;
      background-color:$grayF4;
      color:$gray6;
      font-weight:500;
      font-size:1.25rem;
      @include breakpoint (large) {
        width:40px;
        height:40px;
        font-size:1rem;
      }
      &:hover,&.active-letter {
        background-color:$primary-color;
        color:$white;
      }
      &.disable-letter {
        pointer-events: none;
        opacity:.25;
      }
    }

    // mobile
    .mobile-only-slide-filter-search {
      .row > .column {
        padding-left:.35rem;
        padding-right:.35rem;
        &:first-child {
          padding-left:0;
          a,button {
            background-color:$primary-color;
            color:$white;
          }
        }
        &:last-child {
          padding-right:0;
          a,button {
            background-color:$grayE6;
            border:1px solid $grayEF;
            color:$black;
            display:flex;
            justify-content: space-between;
            text-align: left;
            align-items: center;
            > div {
              width:60%;
            }
          }
        }
      }
      a,button {
        width: auto;
        font-size: .75rem;
        font-weight: 700;
        letter-spacing: 0;
        padding: 0 1rem;
      }
      button {
        width:100%;
        height:52px;
      }
    }
  }
  // listing
  .products-list {
    padding-left:.3rem;
    padding-right:.3rem;
    .hide-brand-item {
      display:none;
    }
    .xlarge-15 {
      @include breakpoint (large) {
        width:20%;
      }
    }
    .column {
      margin-bottom:1rem;
      padding-left:.35rem;
      padding-right:.35rem;
      @include breakpoint (medium) {
        margin-bottom:1.25rem;
        padding-left:.65rem;
        padding-right:.65rem;
      }
    }
    a {
      background-color:$grayF4;
      text-transform: uppercase;
      color:$black;
      font-size:.7rem;
      letter-spacing:.05rem;
      height:170px;
      padding:.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint (medium) {
        height:215px;
        padding:1rem;
      }
      @include breakpoint (large) {
        font-size:.8rem;
        
      }
      .image {
        @include breakpoint (small only) {
          margin-bottom:0;
        }
      }
    }
  }


  
}