///// listing filters / sort ////////
#wheels .wheel-filters, #wheel-brands .wheel-filters {
  h3 {
    font-size:1.15rem;
    color:$primary-color;
    text-transform: uppercase;
    font-weight:700;
  }

  .title {
    border-bottom:1px solid $grayA;
    margin-bottom:2rem;
  }
  .accordion-container {
    margin-bottom:2rem;
  }
  .accordion-title {
    color:$primary-color;
    font-weight:700;
  }
  .accordion-content {
    @include breakpoint (medium down) {
      padding:1rem;
      border-top:1px solid $grayA;
    }
  }

  .fitment-search {
    .button {
      background-color:$primary-color;
      border-radius:10px;
      @include breakpoint (large) {
        margin-bottom:2rem;
      }
      &:hover {
        background-color:$gray6;
      }
    }
  }


  .wheel-filter {
    padding-bottom:2rem;
    //border-right:1px solid $gray6;
    h5 {
      text-transform: uppercase;
      letter-spacing: 0;
      font-size:1.15rem;
    }
    ul {
      list-style: none;
      margin:0;
    }
    li {
      font-weight:400;
      font-size:.875rem;
      line-height:0;
    }
    input {
      margin-bottom:.75rem;
    }
    label {
      line-height:0;
    }
    &.filter-active {
      font-weight:400;
      padding-bottom:2rem;
      ul {
        padding-bottom:1rem;
      }
      li {
        display:inline-block;
      }
      a {
        color:$white;
        background-color:$primary-color;
        font-weight:700;
        padding:.25rem .5rem;
        border-radius:10px;
        display:block;
        &:hover {
          background-color:$gray6;
        }
        i {
          margin-right:.5rem;
        }
        &#clear {
          display:block;
          margin-top:.5rem;
        }
      }
      &-selected {
        padding:.5rem;
        transition: all .2s ease-out;
        display:inline-block;
        border-radius: 5px;
        &:hover {
          background-color:$red;
          color:white;
          a {
            color:$white;
          }
        }
      }
      .filter-active-clear {
        padding-bottom:2rem;
        border-bottom:1px solid $grayA;
        a {
          color:$primary-color;
          font-weight:900;
          background-color:transparent;
          text-transform: capitalize;
          padding:0;
          &:hover {
            color:$gray6;
          }
        }
      }
    }
    .filter-button {
      color:$primary-color;
      font-weight:800;
    }
    &-part-number {
      max-width:80%;
      border-bottom:1px solid $gray6;
      padding-bottom:1rem;
      margin-bottom:1rem;
      input {
        padding:.5rem;
        margin-bottom:0;
      }
      button {
        background-color:$blue;
      }
    }
    &-shopby {
      display:flex;
      align-items: center;
      justify-content: center;
      @include breakpoint (small only) {
        padding-bottom:1rem;
      }
      @include breakpoint (medium) {
        border-bottom:5px solid $gray6;  
        justify-content: flex-start;
        margin-bottom:2rem;
        max-width:80%;
      }
      label,select {
        margin-bottom:0;
      }
      label {
        margin-right:.5rem;
        @include breakpoint (medium) {
          margin-right:1rem;
        }
      }
      select {
        width:auto;
        @include breakpoint (small only) {
          font-size:.9rem;
        }
      }
      h5 {
        color:$red;
        margin-bottom:0;
        @include breakpoint (small only) {
          font-size:1.15rem;
          text-transform: uppercase;
          letter-spacing:1px;
        }
      }
    }
  }
}

// mobile 
#wheels, #wheel-brands, #gallery {
  @include breakpoint (medium down) {
    .filter {
      &-selection {
        border:0;
        padding:.75rem;
      }
    }
    .accordion-item {
      border:1px solid $grayA;
    }
  }
  .accordion {
    &-item {
      &.is-active {
        a {
          //color:$blue;
        }
      }
    }
    &-title {
      font-family:$font-primary;
      text-transform: uppercase;
      font-weight:500;
      border:0;
    }
  }
}

#wheels .wheel-filter, #wheel-brands .wheel-filter {
  #wheel-filter-search {
    display:flex;
    justify-content: space-between;
    align-items: center;

    .brand-name {
      font-size:1.5rem;
      color:$black;
      text-transform: uppercase;
      letter-spacing:.1rem;
      font-weight:700;
      margin:0 1rem;
    }

    .accordion-filter__header {
      text-transform: unset;
    }
  }





  .accordion-filter {
    &__content {
      @include breakpoint (medium down) {
        padding-top:1rem;
        padding-bottom:1rem;
      }
    }
    &__container {
      @include breakpoint (large) {
        justify-content: space-between;
      }
    }

    &__dropdown {
      flex-grow: 0;
      width:100%;
      @include breakpoint (large) {
        margin:0 1rem;
        width:auto;
      }
      select {
        padding:.75rem;
        padding-right:1.5rem;
        font-weight: 400;
        border:1px solid $grayD;
        font-size:.9rem;
      }
      .button {
        margin-bottom:0;
        background-color:$primary-color;
        color:$white;
        padding:.75rem 1.75rem;
        border-radius: 5px;
        font-weight:600;
        text-transform: uppercase;
        font-size:1rem;
      }
    }
  }
  .sort-wheels {
    display:flex;
    flex-direction: column;
    align-items:center;
    @include breakpoint (large) {
      flex-direction:row;
    }
  }
}




///// side filters - fitment ///////
#wheels, #wheel-brands, #gallery {
  .filter {
    &-fitment {
      margin-bottom:3rem;
    }
    .vehicle-title {
      background-color:$primary-color;
      height:54px;
      display:flex;
      align-items:center;
      justify-content: center;
      h4 {
        color:$white;
      }
    }
    .vehicle-select {
      padding:1rem;
      border:1px solid $grayC;
      border-top:0;
      select {
        border-color:$grayC;
        &:disabled {
          background-color:$grayF4;
          color:$gray8;
        }
      }
      .button-group {
        margin-bottom:0;
      }
    }
  }

  // elite
  #fitment-vehicle-select {
    .vs--unsearchable {
      width:100%;
      background-color:transparent;
      margin-bottom:1rem;
      border:1px solid $grayE6;
      input {
        background-color:transparent;
        margin:0;
      }
      .vs__dropdown-toggle {
        border:0;
        padding:0;
        .vs__search {
          //font-weight:600;
        }
      }
      &.vs--disabled {
        background-color:$grayE6;
        input {
          background-color:$grayE6;
        }
        .vs__dropdown-toggle {
          background-color:$grayE6;
          border:0;
        }
        svg {
          background-color:$grayE6;
        }
      }
      .vs__selected {
        font-weight:600;
      }
    }
  }
}

// Icon Filters
.product-filters {
  .icon-product-filter {
    border-bottom:1px solid $grayC;
    padding-bottom:1.5rem;
    margin-bottom:1.5rem;
  }
}
.icon-filter-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-filter-items {
  padding-top:1rem;
  li {
    align-items:center;
    margin-bottom:1rem;
    line-height:1;
    display:flex;
  }
  input {
    margin-bottom:0;
  }
  label {
    line-height:1;
  }
}

///// side filters ///////
#wheels, #wheel-brands, #gallery {
  .filter {
    padding-left:2rem;
    padding-right:2rem;
    h4 {
      text-transform: uppercase;
      font-size:.9rem;
      letter-spacing:.1rem;
      color:$primary-color;
      margin-bottom:0;
      font-weight:700;
    }
    ul {
      list-style: none;
    }
    label {
      font-weight:700;
      text-transform: uppercase;
    }
    i {
      &.fa-minus,&.fa-plus {
        color:$gray8;
        &:hover {
          color:$primary-color;
        }
      }
    }
    .filter-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .filter-button {
      text-transform: uppercase;
      font-size:.75rem;
      font-weight:600;
      color:$gray4;
      &:hover {
        color:$primary-color;
      }
    }
    .filter-items {
      padding-top:1rem;
      display:none;
      li {
        align-items:center;
        margin-bottom:1rem;
        line-height:1;
      }
      input {
        margin-bottom:0;
      }
      label {
        line-height:1;
      }
    }
    .filter-brands .filter-items {
      //display:block;
    }

    .product-filters {
      .product-filter {
        border-bottom:1px solid $grayC;
        padding-bottom:1.5rem;
        margin-bottom:1.5rem;
      }
    }
    // active 
    .filter-active {
      //padding:1rem 2rem;
      margin-bottom:1rem;
      @include breakpoint (medium down) {
        border-bottom:1px solid $grayC;
      }
      a {
        background-color:$grayC;
        color:$black;
        border-radius: 5px;
        padding:.25rem .5rem;
        font-weight:600;
        i {
          color:$primary-color;
          transition: all .3s ease-in-out;
          font-size:inherit;
        }
        &:hover {
          background-color:$primary-color;
          color:$white;
          i {
            color:$white;
          }
        }
        &.fitmentSet {
          pointer-events: none;
          background-color:$gray9;
          i {
            color:$gray6;
          }
        }
      }
      ul {
        li {
          display:inline-block;
          margin-bottom:.5rem;
          margin-right:10px;
        }
      }
      &-clear {
        margin-bottom:1rem;
        @include breakpoint (large) {
          border-bottom:1px solid $grayC;
          padding-bottom:1.5rem;
          margin-bottom:1.5rem;
        }
        a {
          font-size: .75rem;
          font-weight: 600;
          color: $gray4;
          text-transform: uppercase;
          border:none;
          background-color:transparent;
          padding:0;
          &:hover {
            border:none;
            background-color:transparent;
            color:$black;
          }
        }
      }
      .filter-active-title {
        margin-bottom:1rem;
        h4 {
          display:flex;
          align-items:center;
          justify-content: space-between;
          width:100%;
          color:$black;
        }
      }
    }
    .filter-brands {
      label {
        max-width:260px;
        line-height:1;
        vertical-align: middle;
      }
    }
  }
}
