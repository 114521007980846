.hp .cta {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 2rem;
  position: relative;
  @include tear('top', 'gray', 0, 0, 1);
  @include tear('bot', 'black', 0, 0, 1);
  &-txt {
    margin-bottom: 2rem;
  }
  &-header {
    font-size: 1.65rem;
    margin-bottom: 0;
    line-height: 1;
    @include breakpoint(large up) {
      font-size: 4.5rem;
    }
  }
  &-subheader {
    font-size: 1.2rem;
    color: $light-gray;
    font-weight: 500;
    @include breakpoint(large up) {
      font-size: 2rem;
    }
  }
  &-btns {
    position: relative;
    z-index: 2;
  }
  &-btn {
    @include breakpoint(large up) {
      min-width: 12rem;
    }
  }
}
