[data-fc-sidecart] #fc {
  .fc-cart__item__option__value,
  .fc-cart__item__option__name {
      text-transform: capitalize;
  }
  .fc-fixed-button-right  .fc-sidecart-button.fc-btn-action {
    //background: linear-gradient(to bottom, #73b263 0%, #458c33 100%);
    background: $primary-color;
  }
  .fc-action--checkout--button, .fc-button--subscription-cancel, .fc-button--submit {
    background:$primary-color;
    border-color:$primary-color;
  }
  .fc-action--checkout--button:hover, .fc-action--checkout--button:focus, .fc-button--subscription-cancel:hover, .fc-button--subscription-cancel:focus, .fc-button--submit:hover, .fc-button--submit:focus {
    background:$secondary-color;
    border-color:$secondary-color;
  }
  .notice {
    font-weight:400;
  }
}