#see-on-vehicle-detail {
  h5 {
    font-size: 1.5rem;
  }
  select {
    font-size: .875rem;
  }
  .error {
    display: none;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  .no-fitment {
    min-height: 6rem;
    position: relative;
    &::before {
      background: $white;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      opacity: .8;
      position: absolute;
      z-index: 1;
    }
    .error {
      display: block;
    }
  }
  .loadingImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
  }
  .hover-item:hover {
    button {
      opacity: 1;
      transition: opacity .3s ease-in-out;
    }
    img {
      opacity: 0.075;
      transition: opacity .25s ease-in-out;
      -moz-transition: opacity .25s ease-in-out;
      -webkit-transition: opacity .25s ease-in-out;
    }
    &::before {
      opacity: .7;
    }
  }
  button {
    position: absolute;
    top: 40%;
    left: 0;
    padding: 1rem;
    width: 100%;
    font-size: 0.8rem;
    opacity: 0;
    &:hover {
      background-color: #666;
    }
  }
  &-overlay {
    width: 100%;
    height: 100%;
    display: table;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    color: #fff;
    font-size: 50px;
    .fa-spinner {
      display: table-cell;
      vertical-align: middle;
      text-align: center;
    }
  }
}