#tire {
  .spec-information-container {
    .spec-information {
      border-bottom:1px solid $gray9;
      padding-bottom:1rem;
      margin-bottom:2rem;
      font-family:$detail-font-family;
      letter-spacing:.05rem;
      &-title { 
        color:$gray3;
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: .1rem;
        border-bottom:1px solid $gray9;
        margin-bottom:1rem;
      }
      &-item {
        display:flex;
        font-size:.9rem;
        font-weight:500;
        color:$gray3;
        text-transform: uppercase;
        &:nth-child(odd) {
          > div {
            background-color:$grayE;
          }
        }
        > div {
          width:50%;
          padding:.5rem;
          margin:0 2px;
          &:first-child {
            margin-left:0;
          }
          &:last-child {
            margin-right:0;
          }
        }
      }
    }
  }
}