#wheel #specs {
  overflow:unset;
  .spec-tabs-divider {
    border-top:1px solid $primary-color;
    margin-bottom:2rem;
  }
  h2 {
    font-family:$font-primary;
    font-size: 1.5rem;
    color: $gray3;
    letter-spacing: .1rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  #spec-tabs {
    border:0;
    .tabs-title {
      width:auto;
      > a {
        font-size:1.5rem;
        font-weight:700;
        letter-spacing:.1rem;
        color:$gray3;
        padding:1.25rem 1rem;
      }
      &.is-active {
        > a {
          color:$primary-color;
          text-decoration: underline;
          background-color:transparent;
        }
      }
      &:first-child {
        > a {
          padding-left:0;
        }
      }
    }
  }

  .tabs-content {
    background:transparent;
    border:0;
    overflow:auto;
    > .tabs-panel {
      padding:0;
    }
    table {
      background:transparent;
      color:$gray3;
      thead,tbody {
        border:0;
      }
      tr {
        font-weight:500;
        td, th {
          text-align:left;
          padding: 1.25rem 0.625rem;
          &:first-child {
            padding-left:1.5rem;
          }
        }
      }
      thead {
        background:transparent;
        text-transform: uppercase;
        tr {
          background: transparent;
          th {
            background: transparent;
            color:$gray3;
          }
        }
      }
      tbody {
        tr {
          background:transparent;
          &:nth-child(odd){
            background-color:$grayF1;
          }
        }
      }
    }
    .wheel-spec-btn {
      padding: 0.5rem 1rem;
      border-radius: 5px;
    }
    .wheel-spec-link {
      color:$primary-color;
      &:hover {
        color:$gray6;
      }
    }
  }










  .wheel-details-specs-title {
    background-color:$grayD;
    color:$gray6;
    border-bottom:1px solid $gray6;
    font-size:1.25rem;
    text-transform: capitalize;
    font-weight:600;
    a {
      font-size:1.25rem;
      color:$gray6;
      font-weight:600;
    }
    .tabs {
      background-color:transparent;
      border:0;
    }
    .title {
      line-height:1;
      padding:1rem;
    }
    .tabs-title {
      &.is-active {
        > a {
          color:$white;
        }
      }
      > a {
      padding:1rem;
      }
    }
  }


  // sale items
  .spec-sale {
    text-decoration:line-through;
    font-weight:400;
    &-price {
      color:$primary-color;
    }
  }
}

