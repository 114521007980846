#wheel {
  margin-top:3rem;
  margin-bottom:3rem;
  @include breakpoint (large) {
    margin-top:4rem;
    margin-bottom:5rem;
  }
  .row {
    max-width:90rem;
  }
  .wheel-sizes {
    margin-top: 1rem;
    span {
      font-size: 1rem;
      font-weight: bold;
    }
  }
  .wheel-details {
    background: #f1f1f1;
    padding: 1rem 1.5rem;
    .wheel-paragraph {
      font-size: 1rem;
      font-weight: bold;
      text-align: left;
      margin-bottom: 1rem;
      span {
        font-size: 1.2rem;
      }
    }
  }
  .loadingImage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
  }
  .tester {
    display: none;
  }
  .about {
    margin-bottom:1rem;
  }
  //financing / promotion
  .financing {
    padding-right: .625rem;
    padding-left: .625rem;
    @include breakpoint (large) {
      padding-right:.65rem;
      padding-left:.65rem;
    }
  }
}
.gallery-popup {
  display: table;
  margin: 0 auto;
  .galleryView-info {
    color: #ccc;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 1rem;
    span {
      display: block;
    }
  }
  .popup-car {
    font-weight: 900;
    font-size: 1.3rem;
    color: #fff;
  }
  .mfp-close {
    color: white !important;
    position: relative;
    float: right;
  }
}
@media #{$medium-up} {
  .accordion-content {
    padding: 0;
    border: 0;
    border-bottom: 0 !important;
  }
  .alt-images {
    .slick-slide {
      width: 100% !important;
    }
  }
}

.detail-thumb {
  p {
    line-height: 1;
    margin: 0.25rem;
  }
  .finish-name {
    color: $dark-gray;
    font-size: 0.8rem;
  }
  img {
    transition: transform 0.3s ease-in-out;
    &:hover {
      backface-visibility: hidden;
      transform: scale(1.08);
    }
  }
}

#specs {
  margin-bottom: 1.25rem;
  overflow: auto;
}

.see-on-vehicle-container {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0.2rem;
  margin: 0.5rem 0;
}
.see-on-vehicle-header {
  font-size: 1.5rem;
  margin-bottom: 0;
}
.see-on-vehicle-select {
  margin-bottom: 0;
}

table {
  border: 0;
  margin-bottom: 0;

  tr {
    td,
    th {
      text-align: center;
    }

    th {
      background: $light-gray;
    }
  }
}
@media #{$small-only} {
  #wheel {
    .banner-top {
      .wheel-banner {
        height: 6rem;
      }
      .heading {
        font-size: 1.7rem;
        top: 90%;
      }
    }
    .slick-slide {
      display: inline-block;
      float: none;
    }
  }
}
@media #{$medium-up} {
  .finish-header {
    display: none;
  }
}


// popups
.fitmentCheck_text {
  color:$primary-color;
  font-weight:500;
  span {
    color:$black;
    font-weight:600;
  }
}
#will-it-fit-popup {
  h1 {
    color:$primary-color;
    text-align:center;
    font-size:1.6rem;
  }
}

// related popup
#related-popup {
  h4 {
    color:$primary-color;
    font-size:1.15rem;
    font-weight:700;
  }
  ul {
    list-style:none;
    margin:0;
  }
  li {
    border-bottom:1px solid $gray9;
    padding-bottom:1rem;
    margin-bottom:1rem;
  }
  img {
    max-width:100px;
    width:100%;
  }
  label {
    display:flex;
    align-items:center;
    justify-content:space-between;
  }
  .add-additional-to-cart-cta {
    text-align:center;
    > button {
      width:100%;
      &:last-child{
        color:$primary-color;
      }
    }
  }
  .related-item-price {
    
  }
  .strikeout {
    position: relative;
    display:inline-block;
    &:before {
      content: '';
      width: 100%;
      position: absolute;
      right: 0;
      top: 45%;
     
      border-bottom: 2px solid $red;
      -webkit-transform: skewY(10deg);
      transform: skewY(10deg);
    
    }
  }
}