.accessory-image {
  max-width: 500px;
}
.accessory-name {
  font-weight: 700;
  font-size: 1.5rem;
}
.accessory-details {
  background: #f1f1f1;
  padding: 1rem 1.5rem;
}