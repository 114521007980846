.accessory-container {
  margin-bottom: 1rem;
  @media #{ $medium-up } {
    border-bottom: 1px solid #ccc;
  }
  &__left {
    border-right: 1px solid #ccc;
  }
  &__right {
    border-left: 1px solid #ccc;
  }
}
.accessory-popup {
  max-width: 425px;
  padding: 0 0 10px;
  text-align: center;
  &--wide {
    max-width: 850px;
  }
  &__header {
    padding-top: 10px;
    border-bottom: 3px solid #ccc;
    background-color: #f2f2f2;
    color: $primary-color;
    font-size: 1.5rem;
    font-weight: bold;
  }
  &__item {
    width: 90%;
    margin: 0 auto 10px;
    padding-bottom: 15px;
    text-align: left;
    display: grid;
    grid-template-columns: 15% auto auto;
    justify-items: center;
    align-items: center;
    &--border:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
    @media #{ $small-only } {
      border-bottom: 1px solid #ccc;
    }
    .input {
      margin: 0 20px;
      &--right {
        margin: 0;
      }
    }
    .img {
      max-width: 100px;
      @media #{ $small-only } {
        max-width: 90px;
      }
      &--lrg {
        max-width: 50%;
        margin-bottom: 1rem;
      }
    }
  }
  @media #{ $small-only } {
    .title {
      font-size: 0.8rem;
    }
  }
  .title--right {
    display: block;
    text-align: center;
    line-height: 1.2;
  }
  &__link {
    display: inline-block;
    color: $primary-color;
    cursor: pointer;
    font-size: 100%;
    &:hover {
      text-decoration: underline;
    }
  }
}

.accessory-popup--right {
  display: flex;
  align-items: center;
  justify-content: center;
  .input {
    margin: 0;
  }
  .content {
    padding: 0 2rem;
  }
  .subtext {
    color: $primary-color;
    display: block;
    margin-bottom: 1rem;
  }
}
