.info-banner {
  position: relative;
  padding: 3rem 0;
  @include tear('top', 'blue', 0, 0, 1);
  &-header {
    font-size: 1.9rem;
    margin-bottom: 0.5rem;
    letter-spacing: -1px;
    @include breakpoint(medium up) {
      font-size: 4.5rem;
    }
  }
  &-subtitle {
    color: $light-gray;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    @include breakpoint(medium up) {
      font-size: 2rem;
    }
  }
  &-text {
    max-width: 50rem;
    margin: 0 auto;
    font-weight: 500;
    color: #777;
    @include breakpoint(small only) {
      padding: 0.5rem;
      font-size: 0.9rem;
      line-height: 1.3;
    }
  }
}

.hours-banner {
  background-color: $black;
  text-align: center;
  text-transform: uppercase;
  &-txt {
    margin: 0;
    font-weight: 500;
    font-size: 0.9rem;
    @include breakpoint(medium up) {
      font-size: 1.5rem;
    }
  }
}