#snipcart {
  $primary-color:#087ffd;
  $blue:#a4cef1;
  $blue2:#83a8c7;
  $black:#0a0a0a;
  $gray2:#666666;
  $gray:#333333;
  $orange:#ff9c00;

  $placeholder: ('&::-webkit-input-placeholder', '&:-moz-placeholder', 
'&::-moz-placeholder', '&:-ms-input-placeholder', '&::input-placeholder', 
'&::placeholder');

  font-size:100%;
  .snipcart__font--std, .snipcart-form__label {
    font-size:100%;
  }
  // header
  .snipcart-cart-header__title {
    img {
      max-width:175px;
    }
  }
  /*
  .snipcart-overwrite #snipcart-header #snipcart-title {
    color: #50bcd0;
    background: url('https://images.iconfigurators.app/images/websites/logos/logo-color_5790.png') no-repeat 0 center;
    padding-left: 60px;
  }*/
  // empty cart
  .snipcart-button-secondary.snipcart-base-button.is-fit-content.is-icon-left {
    background-color:$primary-color;
    color:$white;
  }
  // cart summary 
  .snipcart-discount-box .snipcart-input {
    padding-right:0;
  }
  .snipcart-summary-fees__notice.snipcart__font--regular span {
    visibility: hidden;
    position: relative;
  /*  &:after {
      content:'goodbye'; 
      visibility: visible;
      display: block;
      position: absolute;
      background-color: red;
      padding: 5px;
      top: 2px;
    }*/
    &.snipcart-summary-fees__notice-ic-opt {
      visibility: visible;
    }
  }
  .snipcart-discount-box__button.snipcart__font--bold {
    visibility: hidden;
    position:relative;
    
  }
  .snipcart-discount-box__button.snipcart__font--bold:after {
    content:'Apply Gift Certificate'; 
    visibility: visible;
    display: block;
    position: absolute;
    padding: 5px;
    top: 2px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 16px 0;
    text-align: center;
    width: 100%;
    border: 1px solid;
    color: #2e3338;
    color: var(--color-input,#2e3338);
    border-color: #e3e6e8;
    border-color: var(--borderColor-input,#e3e6e8);
    background-color: #fff;
    background-color: var(--bgColor-input,#fff);
  }
  .snipcart-discount-box__form-container {
    /*
    .snipcart-input {
      &:after {
        content:'Gift Code';
        position: absolute;
        left:0;
        padding-left:.5rem;
        color: #2e3338;
        color: var(--color-input,#2e3338);
      }
    }*/
    .snipcart-input__input.snipcart__font--secondary.snipcart__font--bold {
      //visibility: hidden;
      @each $selector in $placeholder {
        #{$selector} {
          //visibility: hidden;
          position: relative;
          &::after {
            position: absolute;
            color:black;
            visibility: visible;
            content:'hello there';
            background-color:green;
          }
        }
      }
    } 
  }

  // sidecart 
  .snipcart-cart-summary-side {
    @media (min-width: 768px) {
      &.snipcart-cart-summary--edit.snipcart-modal__container {
        width: 100%;
        max-width: none;
        background-color: rgba(0,0,0,.7);
        .snipcart-layout {
          max-width: 540px;
          margin-right:0;
        }
      }
    }
  }
  
  // checkout
  .snipcart-input {
    .snipcart-input__input {
      margin-bottom:0;
      height:52px;
    }
  }
  .snipcart-form-radio__label {
    display:flex;    
  }
  .snipcart-form__field {
    > label {
      font-weight:400;
    }
  }
  .snipcart-cart-summary-item {
    > span {
      font-weight:400;
    }
  }
  // theme
  .snipcart-cart-header .snipcart-modal__close-icon, .snipcart-cart-header__icon, .snipcart-payment-methods-list-item__arrow {
    //color:$blue;
  }
  .snipcart-form__address-autocomplete>.snipcart-textbox, .snipcart-textbox {
    border-color:$gray2;
  }
  .address-disclaimer {
    margin-bottom:2rem;
  }
  .snipcart__box--badge-highlight, .snipcart-button-icon.is-secondary {
    //background-color:$blue;
  }
  .snipcart__box--badge-highlight:hover, .snipcart-button-icon.is-secondary:hover {
    background-color:$primary-color;
    color:$white;
  }
  .snipcart-item-quantity__quantity:hover, .snipcart-discount-box__button:hover {
    border-color:$blue;
  }
  .snipcart-button-primary {
    background-color:$primary-color;
  }
  .snipcart-button-link {
    //color:$blue2;
  }
  .snipcart-button-link:hover {
    color:$gray;
  }
  // notice
  .notice {
    margin-top:2rem;
    p {
      line-height:1.5;
      font-weight:400;
    }
    b {
      font-weight:700;
    }
  }
}


/*
$red:#fe3232;

#snipcart {
  ///// checkout /////
  input {
    margin-bottom:0;
  }
  .snipcart-input {
    height:auto;
  }
  .im-custom-subtitle {
    margin-top:2rem;
  }

  .address-disclaimer {
    margin-top:1rem;
    p {
      font-weight:600;
      color:$red;
    }
  }

  .snipcart-featured-payment-methods__list {
    align-items:center;
    justify-content: center;
    &-item {
      padding:8px;
    }
  }
}

.snipcart-form-radio__label {
  display: flex!important;
}
*/