#wheel .finishes {
  margin-bottom:2rem;
  a {
    display:block;
    max-width:112px;
    float:left;
    color:$black;
    line-height:1.25;
    font-weight:500;
    margin:0 .25rem;
    margin-bottom:.5rem;
    @include breakpoint (large) {
      margin-left:0;
      margin-right:.75rem;
    }
    &:hover {
      color:$primary-color;
    }
  }
  .label {
    background-color:transparent;
    color:$black;
    text-transform: uppercase;
    font-size:1rem;
    font-weight:600;
    margin-bottom:1rem;
    padding:0;
  }
  .image {
    background-color:$grayF4;
    margin-bottom:.5rem;
  }
  .finish-name {
    font-size:.8rem;
  }
}