.featured-brands {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #eaeaea;

  @include breakpoint(medium up) {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }

  &__header {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;

    @include breakpoint(medium up) {
      font-size: 4.5rem;
      margin-bottom: 1rem;
    }
  }

  &__text {
    color: $light-gray;
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;

    @include breakpoint(medium up) {
      padding: 0 5rem;
      margin-bottom: 2rem;
      font-size: 2.1rem;
    }
  }

  &__bottom {
    margin-bottom: 4rem;
  }
}

.brand-links {
  max-width: 65rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(large up) {
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 3rem;
    display:flex;
    justify-content: center;
  }

  &__link {
    background-color: $white;
    margin: 1px;
    display:flex;
    align-items:center;
    justify-content: center;
    @include breakpoint (large) {
      max-width:206px;
      width:100%;
    }

    &:first-child {
      grid-column-start: 1;
    }

    &:hover {
      background-color: $light-gray;
      transition: 0.3s ease-in-out;
    }
  }
}