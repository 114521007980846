/* ==========================================================================
   0. Variables
   ========================================================================== */

$red: #ff0d0d;
$blue: #025193;
$blueRGBA: rgba(2,81,147,.9);
$blue2: #087ffd;
$blue3: #285e8e;
$green:#2ca900;
$green2:#3ea20a;
$orange:#ff4d0d;
$pink: #f9632e;

$gray3:#333333;
$gray4:#444444;
$gray6: #666666;
$gray8:#888888;
$gray9: #999999;
$grayA:#aaaaaa;
$grayB9: #b9b9b9;
$grayC: #cccccc;
$grayD:#dddddd;
$grayD4:#d4d4d4;
$grayE:#eeeeee;
$grayEA: #eaeaea;
$grayEF:#efefef;
$grayE6:#e6e6e6;
$grayF1:#f1f1f1;
$grayF4: #f4f4f4;
$whiteF1:$grayF1;

$primary-color:$blue;
$primary-color-transparent:$blueRGBA;
$secondary-color:$blue2;

// fonts
$detail-font-family: 'Arial', sans-serif;
/* ==========================================================================
   1. General (Foundation Overrides)
   ========================================================================== */

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 900;
}

body {
  font-weight: 300;
}

h1,
h2,
h3 {
  color: #000000;
  text-transform: uppercase;
  line-height: 1;
}

h4,
h5,
h6 {
  font-weight: 700;
  letter-spacing: -1px;
}

h1 {
  font-size: 5.44rem;
}

h2 {
  font-size: 4.18rem;
}

h3 {
  font-size: 3.1rem;
}

h5 {
  font-size: 2.09rem;
}

h6 {
  font-size: 1.15rem;
}

.h1 {
  font-size: 1.5rem;
}

.h2 {
  font-size: 1.8rem;
  font-weight: 700;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #999;
}

body {
  font-family: $body-font-family;
  color: $medium-gray;
  font-weight: 300;
  line-height: 1.7;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 1rem;
  color: $black;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 32px;
}

a {
  color: $black;
  &:hover {
    color: #666666;
  }
}

ul,
ol {
  margin-right: 0;
  margin-bottom: 0;
}

table {
  min-width: 100%;
  margin-bottom: 0;
  border: 0;
}

table tr td,
table thead tr th {
  text-align: center;
}

table thead tr th {
  color: #fefefe;
  background: #707070;
}

.row--short {
  max-width: 65rem;
  margin-left: auto;
  margin-right: auto;
}

.bg-img {
  background-color: #333;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hidden {
  display: none !important;
}

.reveal {
  display: block;
}

.product-paragraph {
  color: #000;
  margin-bottom: 0.5rem;
  text-align: left;
  margin-right: 1rem;
}

.info {
  &--limit {
    line-height: 1.2em;
    overflow: hidden;
    margin-bottom: 0;
  }
  &--height {
    height: 6em;
  }
}

.parallax {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-bottom:2rem;
  @include breakpoint (large) {
    margin-bottom:0;
  }
  h1,
  h2 {
    position: relative;
    padding: 1.8rem 1rem;
    font-size: 2rem;
    top: 25%;
  }
  .img {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #333;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .bg-top {
      background-position: center top;
    }
  }
  .img:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    opacity: 0.4;
    background: #000;
  }
}

.white-popup {
  background: $white;
  margin: 20px auto;
  max-width: 400px;
  padding: 20px;
  position: relative;
  width: auto;
  .popup-header {
    font-size: 1.2rem;
  }
  .button-group {
    margin-bottom: 0;
    overflow: auto;
  }
}

.no-results {
  color: $black;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  color: white;
  display: none;
  &--nav {
    display: block;
    top: 3.2rem;
  }
}

.primary {
  color: $primary-color;
}

.secondary {
  color: $secondary-color;
}

.primary-bg {
  background-color: $primary-color;
}

.primary-btn {
  border-radius: 4px;
  padding: 0.8rem 1.9rem;
  margin: 0;
  font-weight: 700;
  a {
    color: $white;
  }
}

.red-bg {
  background-color: #d40000;
}

.black-font {
  color: #333;
}

.red {
  color: #d40000;
}

.gray {
  color: $medium-gray;
}

.white {
  color: #fefefe;
}

.button {
  font-weight: bold;
  background-color: $primary-color;
}

.rounded {
  border-radius: 500px;
}

.no-margin {
  margin-bottom: 0;
}

.button.secondary {
  background-color: #e7e7e7;
  color: #323232;
}

.black,
.button.black {
  background-color: #000000;
}

.button.black:hover {
  background-color: $medium-gray;
}

.button,
.text-uppercase {
  text-transform: uppercase;
}

.button:hover {
  background-color: #000000;
}

.button-group .button {
  padding-right: 0;
  padding-left: 0;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.font-heavy {
  font-weight: 900;
}

.font-italic {
  font-style: italic;
}

.g-recaptcha {
  margin-bottom: 0.5rem;
  display: inline-block;
}

#captchaCheck {
  float: left;
  margin-left: 1rem;
  text-transform: uppercase;
}

.padded {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padded.extra {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.padded.short {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.padded-left {
  padding-left: 0.5rem;
}

.padded-right {
  padding-right: 0.5rem;
}

.container-padding--b {
  padding-bottom: 4rem;
}

.accordion .accordion-navigation>.content {
  padding-right: 0;
  padding-left: 0;
}

.accordion-item:first-child> :first-child {
  font-weight: 700;
}

.anchor {
  position: relative;
  top: -3rem;
  display: block;
  visibility: hidden;
}

.full-width {
  width: 100%;
  max-width: 100%;
}

.fixed {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
}

.title-bar,
.top-bar {
  padding: 0;
}

.top-bar,
.top-bar ul {
  background-color: transparent;
}

.top-bar ul.is-dropdown-submenu {
  background-color: rgba(255, 255, 255, 1);
  border: 0px;
}

.dropdown.menu .is-dropdown-submenu-parent.is-down-arrow>a::after {
  right: 0px;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a {
  padding-right: 1rem;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a:after {
  border-color: #d40000 transparent transparent;
  right: 0px;
  display: none;
}

.dropdown.menu.vertical>li.opens-right>a::after {
  border-color: transparent transparent transparent $primary-color;
  right: 5px;
}

.dropdown.menu>li>a:hover,
.dropdown.menu>li>ul li a:hover,
.dropdown.menu>li.is-active>a {
  color:$secondary-color;
}

.dropdown.menu>li>ul li a {
  padding: 0.5rem 0rem;
}

.dropdown.menu>li.opens-right>.is-dropdown-submenu {
  top: 154%;
  min-width: 280px;
  padding-bottom: 20px;
}

address {
  font-style: normal;
}

#instafeed .column {
  padding: 1px;
}

@media only screen and (min-width: 40.063em) {
  .int.site-content .h2 {
    font-size: 2.6rem;
  }
}

.block-sm {
  @include breakpoint(small only) {
    display: block;
  }
}

/********* VIDEO *********/

#videos {
  margin-top: 3rem;
  a:hover {
    color: #666;
  }
  a::before {
    background: rgba(255, 255, 255, 0);
  }
  p {
    font-size: 1.6rem;
  }
  #next-button {
    background-color: #fff;
  }
  #video-container .column a {
    position: relative;
    display: block;
  }
}

// ********** POPUP ********** //
.white-popup--wide {
  max-width: 700px;
}

.processing {
  background: transparent;
  color: $white;
  text-align: center;
}

// ********** HOVER ITEMS ********** //
.hover-item-link {
  display: block;
  padding: 1rem 0;
}

.hover-item {
  position: relative;
  display: block;
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  text-align: center;
  &:hover {
    .hover-item__img {
      opacity: 1;
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
  }
  &__header {
    line-height: 1.2;
    margin-bottom: 0;
    font-size: 1.2rem;
  }
  &__header,
  h6 small {
    color: #545454;
  }
  &__img {
    -webkit-transition: -webkit-all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 2.7rem;
  }
  h2 {
    font-size: 2.1rem;
  }
  h3 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
}

// pagination
.pagination {
  .current {
    background-color:$primary-color;
  }
}

/* ==========================================================================
   5. Brands
   ========================================================================== */

.selected-vehicle-container {
  padding: 0;
}

#wheel-brands {
  .filters {
    h5 {
      margin-bottom: 0;
      font-family: $font-thirdary;
    }
  }
  .filter_button {
    border-radius: 0;
    font-size: 1rem;
    font-family: $font-thirdary;
  }
  .filter_select {
    select {
      margin-bottom: 0;
    }
  }
}

/* ==========================================================================
   5. Brand (Product) Landing page
   ========================================================================== */

/*#wheels {
  //// filter ////
  .product-filter {
    padding-top: 0;
    background-color: #3f4851;
    h5 {
      margin-bottom: 0;
      font-family: $font-thirdary;
    }
    .filter_button {
      border-radius: 0;
      font-size: 1rem;
      font-family: $font-thirdary;
    }
    .filter_select {
      select {
        margin-bottom: 0;
      }
    }
  }
}*/

/* ==========================================================================
   6. Tire Details
   ========================================================================== */

.brand-details {
  a {
    margin-bottom: 2rem;
    display: block;
  }
}

// ********** Magnific Popup Overrides ********** //
.mfp-img {
  background-color: #fff;
}

.mfp-image-holder .mfp-close {
  color: #000;
  right: 0;
}

/* ==========================================================================
   6.5. loading spinner
   ========================================================================== */
   .lds-ellipsis,
   .lds-ellipsis div {
     box-sizing: border-box;
   }
   .lds-ellipsis {
     display: inline-block;
     position: relative;
     width: 80px;
     height: 80px;
   }
   .lds-ellipsis div {
     position: absolute;
     top: 33.33333px;
     width: 13.33333px;
     height: 13.33333px;
     border-radius: 50%;
     background: currentColor;
     animation-timing-function: cubic-bezier(0, 1, 1, 0);
   }
   .lds-ellipsis div:nth-child(1) {
     left: 8px;
     animation: lds-ellipsis1 0.6s infinite;
   }
   .lds-ellipsis div:nth-child(2) {
     left: 8px;
     animation: lds-ellipsis2 0.6s infinite;
   }
   .lds-ellipsis div:nth-child(3) {
     left: 32px;
     animation: lds-ellipsis2 0.6s infinite;
   }
   .lds-ellipsis div:nth-child(4) {
     left: 56px;
     animation: lds-ellipsis3 0.6s infinite;
   }
   @keyframes lds-ellipsis1 {
     0% {
       transform: scale(0);
     }
     100% {
       transform: scale(1);
     }
   }
   @keyframes lds-ellipsis3 {
     0% {
       transform: scale(1);
     }
     100% {
       transform: scale(0);
     }
   }
   @keyframes lds-ellipsis2 {
     0% {
       transform: translate(0, 0);
     }
     100% {
       transform: translate(24px, 0);
     }
   }
   
   



/* ==========================================================================
   7. FOUC - flash of unstyled content
   ========================================================================== */
   .no-js {
    display:none;
  }



/* ==========================================================================
   8. Honeypot
   ========================================================================== */
.ohnohoney {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}