.pkg-img {
  position: relative;
  &-wheel {
    width: 80%;
    margin-left: auto;
    display: block;
  }
  &-tire {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 55%;
  }
}

.pkg-info {
  margin-bottom: 1rem;
  &-header {
    font-size: 1.2rem;
  }
  &-wheel,
  &-tire {
    font-weight: 400;
    color: $black;
  }
  &-price {
    font-weight: 700;
    color: $black;
  }
  .red {
    color: $primary-color;
  }
}

.pkg-btn {
  width: 75%;
}


// blowout
.no-blowout {
  height:80vh;
  padding-top:2rem;
  padding-bottom:2rem;
  text-align:center;
  @include breakpoint (large) {
    padding-top:4rem;
    padding-bottom:4rem;
  }
  h4 {
    margin-bottom:3rem;
  }
  p {
    font-weight:500;
  }
  a {
    color:$primary-color;
    &:hover {
      color:$gray9;
    }
  }
}
