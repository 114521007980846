#packages {
  .listing {
    // head - selected vehilce / sort //////////////////
    .head {
      display:flex;
      justify-content: space-between;
      flex-direction:column;
      @include breakpoint (large) {
        flex-direction: row;
      }
      .vehicle-selected {
        font-size:1.25rem;
        font-weight:700;
        color:$black;
        text-transform:uppercase;
        margin-bottom:.5rem;
        .divider {
          color:$primary-color;
        }
      }
      .sort {
        select {
          font-family:$body-font-family;
          background-image:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28208, 167, 1%29'></polygon></svg>");
          border-color:$grayC;
        }
      }
    }
    .content {
      a {
        border:1px solid $grayC;
        display:block;
        margin-bottom:2rem;
        position: relative;
        padding:2rem 1rem 1.5rem;
        text-transform: uppercase;
        line-height:1.1;
        font-size:1.25rem;
        font-weight:700;
        color:$black;
        &:hover {
          color:$primary-color;
        }
        i {
          font-weight:600;
        }
      }
      .item {
        > div {
          margin-bottom:1rem;
          &:not(.price,.image) {
            padding-left:2rem;
            padding-right:2rem;
          }
          &.description,&.additional {
            margin-bottom:2rem;
          }
        }
      }
      .price {
        background: linear-gradient(142deg, $gray4 0, $gray4 50%, transparent 50%, transparent 100%);
        position: absolute;
        top:0;
        left:0;
        width:170px;
        height:130px;
        text-align:left;
        padding:.75rem;
        text-transform: uppercase;
        line-height:1.1;
        color:$white;
        .title {
          font-size:.8rem;
          font-weight:400;
        }
        .value {
          font-weight:700;
          font-size:1.5rem;
        }
      }
      .image {
        position:relative;
        .wheel {
          max-width:80%;
          margin-left:auto;
        }
        .tire {
          position: absolute;
          bottom:0;
          left:0;
          max-width: 55%;
        }
      }
      .description {
        margin-bottom:2rem;
        .vehicle {
          margin-bottom:1rem;
        }
        .wheel,.tire {
          color:$black;
          font-size:.9rem;
          line-height:1.25;
          margin-bottom:1rem;
          > div {
            display:inline;
          }
        }
        .sku {
          font-weight:400;
        }
      }
      .cta {
        .button {
          //max-width:300px;
          width:100%;
        }
      }

      .additional {
        font-size:1rem;
        color:$black;
        margin-bottom:2rem;
        .title {
          margin-bottom:.75rem;
          i {
            color:$primary-color;
          }
        }
        &-list {
          ul {
            list-style: none;
            margin:0;
          }
          li {
            margin-bottom:.5rem;
          }
          span:not(.fas) {
            font-weight:400;
            text-transform: none;
          }
          .icon {
            height:28px;
            width:28px;
            border-radius:25px;
            color:$white;
            display:inline-flex;
            align-items:center;
            justify-content:center;
            &.pink,&.green {
              margin-right:.25rem;
            }
            &.pink {
              background-color:$pink;
            }
            &.green {
              background-color:$green2;
            }
          }
        }
      }
    }
  }
}