.grid-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  position: relative;
  @include tear('top', 'gray', 0, 0, 1);
  @include tear('bot', 'gray', 0, 0, 1);
  @include breakpoint(medium only) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include breakpoint(large up) {
    grid-template-columns: repeat(4, 1fr);
  }
  img {
    width: 100%;
  }
}
.grid-link {
  position: relative;
  :hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  &__txt {
    position: absolute;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    @include breakpoint(medium up) {
      font-size: 1.5rem;
    }
  }
}
