#gallery-top {
    padding-top: 5.5rem;
}

.gallery-content {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.gallery-breadcrumbs {
    font-size: 0.875rem;
    text-transform: capitalize;
    font-weight: 800;
    color: $dark-gray;

    &-container {
        background-color: #f0f1f0;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    a {
        color: $dark-gray;

        &:hover {
            color: $primary-color;
        }
    }
}

.gallery-title {
    padding-bottom: 2rem;

    h1 {
        font-size: 2rem;
        margin: 0;
    }
}

#gallery-main-image {
    margin-bottom: 1rem;

    img {
        max-height: 513px;
        object-fit: contain;
    }
}

#gallery-thumbs {
    margin-bottom: 1rem;
}

#gallery-thumbs .slick-dots {
    bottom: 0 !important;
    position: relative;
    margin: 0;
}

.gallery-details {
    font-weight: 500;
    color: #333;
    font-weight: 600;
    margin-bottom: 2rem;

    .container {
        position: relative;
        padding: 1.25rem 1.25rem;
        font-size: 1.15rem;
        border-left: 1px solid $primary-color;
        border-right: 1px solid $primary-color;

        &:not(:first-of-type):not(:last-of-type):after {
            content: '';
            position: absolute;
            border-bottom: 1px solid #ccc;
            width: 90%;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
        }

        &:last-of-type {
            border-bottom: 1px solid $primary-color;
        }
    }

    // build
    .build-title {
        background-color: $primary-color;
        text-transform: uppercase;
        color: $white;
        font-size: 1.3rem;
        line-height: 1;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .build-accessory,
    .build-wheel {
        a {
            color: $primary-color;
        }

    }

    .build-item {
        display: grid;
        grid-template-columns: 30% auto;
        grid-column-gap: 1rem;
        align-items: center;
    }

    .build-name {
        color: $primary-color;
    }
    .build-description {
      margin-top:1rem;
      p {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom:0;
      }
    }
    .wheel-title {
        color: #333;
    }

    .tire-thumb {
        max-width: 100px;
    }

    .tire-name {
        color: $primary-color;
        font-size: 1.4rem;
        margin-left: 0.5rem;
    }

    // Contact
    .gallery-contact {
        text-align: center;

        .contact-title {
            background-color: #333;
            color: $white;
        }

        .contact-info {
            text-transform: capitalize;

            a {
                color: $dark-gray;
                display: flex;
                align-items: center;

                &:hover {
                    color: $primary-color;
                }
            }

            i {
                font-size: 2rem;
            }

            .contact-icon {
                width: 32px;
                height: 32px;
                text-align: center;
                margin-right: 1rem;
            }

            &-dealer {
                padding-bottom: 1rem;
            }
        }
    }
}