.hp .featured {
  margin-bottom:2rem;
  a {
    display:block;
    margin:0.4rem;
    max-width:300px;
  }
  .hover-item {
    border:1px solid rgba(10, 10, 10, 0.2);
    .thumbnail {
      box-shadow: none;
    } 
    .information {
      background-color:$grayEA;
      text-align:left;
      padding:.75rem;
      line-height:1rem;
      text-align:center;
      @include breakpoint (large) {
        padding:1rem;
      }
      .brand,.wheel-name {
        padding-bottom:.75rem;
      }
      .brand {
        font-weight:600;
        color:$gray9;
        font-size:.9rem;
        /*@include breakpoint (large) {
          font-size:1rem;
        }*/
        
      }
      .wheel-name {
        text-transform: uppercase;
        font-weight:700;
        font-size:1rem;
        line-height:1.25;
        /*@include breakpoint (large) {
          font-size:1.35rem;
        }*/
      }
    }
  }
  .featured-slider {
    display:flex;
  }
  .featured-arrow {
    font-size:2rem;
    width:10%;
  }
  .slick-list {
    .featured-arrow {
      display:none!important;
    }
  }
}