// view on vehicle preview
.mobile-vehicle-image {
  text-align: center;
  margin-bottom: 0.5rem;
  display: block;
  padding-top:1rem;
  img {
    width:50%;
  }
  h3 {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  a {
    color:$primary-color;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.7rem;
    display: block;
  }
}


// spinner

.mfp-content {
  .lds-ellipsis {
    color:$grayB9;
  }
  .processing-title {
    margin-top:-25px;
    color:$grayB9;
    text-transform:uppercase;
  }
}

// popup
#fitment-popup {
  .popup_header {
    color:$primary-color;
  }
  a {
    color:$primary-color;
  }
}
#fitment-vehicle-select-popup {
  select {
    &:disabled {
      background-color:$grayF4;
      color:$gray8;
    }
  }
}