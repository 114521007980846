#contact p,
#thanks p,
#contact li,
#thanks li,
#gallery p,
#gallery li {
    color: #999999;
    font-weight: 400;
    padding-top: 0px;
}

#contact h3,
#thanks h3,
#gallery h3 {
    font-size: 2rem;
    margin-bottom: 0px;
}

#contact p.red {
    color: #d40000;
    font-size: 1rem;
    font-weight: 600;
}

#form-right {
    li {
        padding-top: .25rem;
        padding-bottom: .25rem;
    }
    .fa {
        font-size: 2.25rem;
    }
    h3 {
        font-size: 1.6rem;
        letter-spacing: -1px;
    }
    address {
        margin-bottom: 20px;
    }
}