.wheel-info--long {
  padding: 0.5rem;
  @include breakpoint(large up) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  &__lg {
    font-size: 2.3rem;
    font-weight: 900;
    margin-bottom: 0.5rem;
  }
  &__sm {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0;
  }
}
