.details-media {
  z-index: 5;
  text-align: center;
  &__header {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    letter-spacing: 0;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-transition: color 0.3s ease-out;
    -moz-transition: color 0.3s ease-out;
    -o-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
    color: #000;
    &:hover {
      color: $primary-color;
    }
  }
}

.details-divider {
  padding-bottom: 20px;
  &:before {
    background-color: #c7c7c9;
    content: '';
    display: block;
    height: 1px;
    margin: 15px auto 0;
    width: 2rem;
  }
}

@include breakpoint(small only) {
  .details-360,
  .details-video {
    display: inline-block;
    margin: 0 1rem;
  }
  .details-divider {
    display: none;
  }
}
